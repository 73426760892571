import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';

// Use React.memo to prevent unnecessary re-renders if the component props remain the same
const HeroSection = React.memo(() => {
    return (
        <Container
            maxWidth={false}
            disableGutters
            sx={{
                backgroundColor: '#f2f2fc',
                width: '100%',
            }}
        >
            <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
                sx={{ minHeight: '60vh', textAlign: 'center' }}
            >
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            width: '80%',
                            p: 2, // Padding shorthand
                        }}
                    >
                        <Typography
                            variant="h4"
                            gutterBottom
                            sx={{
                                fontFamily: 'Space Grotesk',
                                fontWeight: 600,
                                color: '#26274B',
                                mb: 2, // Margin-bottom shorthand
                                fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem', lg: '3rem' },
                            }}
                        >
                            All Your Tools,
                            <span style={{ color: '#4179ff', padding: '0 4px', borderRadius: '4px', fontWeight: 700 }}>
                                "Just a Click Away!"
                            </span>
                        </Typography>

                        <Typography
                            variant="body1"
                            sx={{
                                fontFamily: 'Space Grotesk',
                                fontWeight: 300,
                                mb: 2,
                                fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem', lg: '1.25rem' },
                            }}
                        >
                            No need to bookmark the tools you like separately.
                        </Typography>

                        <Typography
                            variant="body1"
                            sx={{
                                fontFamily: 'Space Grotesk',
                                fontWeight: 300,
                                mt: 0,
                                fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem', lg: '1.25rem' },
                            }}
                        >
                            <span style={{ color: '#4179ff', padding: '0 4px', borderRadius: '4px' }}>
                                smallprep.com
                            </span>{" "}
                            is a “free all-in-one toolbox” solution created to ease your life by preventing bookmark mess.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
});

export default HeroSection;
