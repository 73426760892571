// components/Loading.js
import React from 'react';
import './Loading.css'; // Import the CSS file for animations
import rocketImage from '../assets/rocket.png'; // Import the rocket image

const Loading = () => {
  return (
    <div className="loading-container">
      <img
        src={rocketImage}
        alt="rocket"
        className="rocket-emoji"
      />
      <div className="loading-text">Loading...</div>
    </div>
  );
};

export default Loading;
