import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import rocketImage from '../assets/rocket.png'; // Import the rocket image

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate(); // Initialize the navigate function

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setTimeout(() => setAnchorEl(null), 100); // Slight delay for smoother closing
  };

  const handleMenuItemClick = (path) => {
    navigate(path); // Navigate to the specified path
    handleClose(); // Close the menu
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ backgroundColor: '#f2f2fc', color: 'black', width: '100%' }}>
        <Toolbar style={{ padding: '10px' }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, display: { xs: 'block', md: 'none' } }}
            onClick={handleMenu}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, cursor: 'pointer' }} // Make the title clickable
            style={{
              fontFamily: 'Space Grotesk',
              fontWeight: 600,
              color: "#26274B",
              transition: 'color 0.3s ease',
              display: 'flex', // Make it a flex container to position the rocket and text nicely
              alignItems: 'center',
            }}
            onClick={() => navigate('/')} // Navigate back to homepage when clicked
            onMouseEnter={(e) => {
              e.target.style.color = '#4179ff';
              document.querySelector('.rocket').style.animation = 'rocketLaunch 0.5s ease-out'; // Trigger the rocket animation
            }}
            onMouseLeave={(e) => {
              e.target.style.color = '#26274B';
              document.querySelector('.rocket').style.animation = ''; // Reset the animation
            }}
          >
            {/* Replace the emoji with the rocket image */}
            <img
              src={rocketImage}
              alt="rocket"
              className="rocket"
              style={{ marginRight: '8px', width: '24px', height: '24px', transition: 'transform 0.3s ease' }}
            />
            smallprep
          </Typography>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Button
              color="inherit"
              onClick={() => navigate('/')} // Navigate to the landing page
              sx={{
                fontFamily: 'Space Grotesk',
                fontWeight: 300,
                color: "#26274B",
                transition: 'color 0.3s ease, font-weight 0.3s ease, text-decoration-line 0.3s ease',
                '&:hover': {
                  backgroundColor: 'transparent', // Keeps the background transparent on hover
                  color: '#4179ff', // Changes the text color on hover
                  fontWeight: 600,
                  textDecorationLine: 'underline',
                },
              }}
            >
              Explore Tools
            </Button>
          </Box>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{ display: { xs: 'block', md: 'none' } }}
          >
            <MenuItem onClick={() => handleMenuItemClick('/')}>Explore Tools</MenuItem> {/* Redirect to the landing page */}
          </Menu>
        </Toolbar>
      </AppBar>

      {/* Inline CSS for keyframes */}
      <style>
        {`
          @keyframes rocketLaunch {
            0% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-10px);
            }
            100% {
              transform: translateY(0);
            }
          }

          .rocket {
            display: inline-block;
          }
        `}
      </style>
    </Box>
  );
};

export default Navbar;
