import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const FooterComponent = () => {
    return (
        <Box
            component="footer"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                backgroundColor: '#26274B',
                color: '#FFFFFF',
                marginTop: 'auto',
                width: '100%',
                textAlign: 'center',
                paddingBottom: '10px',
                paddingTop: '10px'
            }}
        >
            <Typography
                variant="body2"
                sx={{
                    fontFamily: 'Space Grotesk',
                    fontWeight: 300,
                    color: '#FFFFFF',
                    fontSize: { xs: '12px', sm: '14px' }, // Smaller font size on extra small screens
                }}
            >
                © {new Date().getFullYear()} smallprep.com. All rights reserved.
            </Typography>
            <Link
                href="https://smallprep.com/"
                target="_blank"
                rel="noopener"
                sx={{
                    fontFamily: 'Space Grotesk',
                    fontWeight: 400,
                    color: '#2e4ea2',  // Darker shade for better contrast
                    textDecoration: 'none',
                    marginTop: '10px',
                    '&:hover': {
                        color: '#FFFFFF',
                        textDecoration: 'underline',
                    },
                }}
            >
                Visit smallprep.com
            </Link>

        </Box>
    );
};

export default FooterComponent;
