import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar';
import HeroSection from './components/herosection';
import ToolsSection from './components/toolssection';
import FooterComponent from './components/footersection';
import Loading from './components/Loading'; // Import the Loading component

const AgeCalculatorComponent = lazy(() => import('./components/tools/ageCalculator'));
const CaseConverterComponent = lazy(() => import('./components/tools/caseConverterComponent'));
const TemperatureConverterComponent = lazy(() => import('./components/tools/temperatureConverterComponent'));
const LetterCounterComponent = lazy(() => import('./components/tools/letterCounterComponents'));
const CoinTossComponent = lazy(() => import('./components/tools/coinTossComponents'));
const CharToAsciiComponent = lazy(() => import('./components/tools/chartoAsciiConverter'));
const TimestampToDateConverter = lazy(() => import('./components/tools/timestampToDateConverter'));
const LoremIpsumGenerator = lazy(() => import('./components/tools/loremIspumGenerator'));
const HexToRgbConverter = lazy(() => import('./components/tools/hexToRgbConverter'));

function LandingPage() {
  return (
    <>
      <HeroSection />
      <ToolsSection />
    </>
  );
}

function App() {
  return (
    <Router>
      <Navbar style={{ backgroundColor: 'white', width: '100%' }} />
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/tools/age-calculator" element={<AgeCalculatorComponent />} />
          <Route path="/tools/case-converter" element={<CaseConverterComponent />} />
          <Route path="/tools/temp-converter" element={<TemperatureConverterComponent />} />
          <Route path="/tools/letter-counter" element={<LetterCounterComponent />} />
          <Route path="/tools/coin-toss" element={<CoinTossComponent />} />
          <Route path="/tools/char-to-ascii" element={<CharToAsciiComponent />} />
          <Route path="/tools/timestamp-to-date-converter" element={<TimestampToDateConverter />} />
          <Route path="/tools/lorem-ispum-generator" element={<LoremIpsumGenerator />} />
          <Route path="/tools/hex-to-rgb" element={<HexToRgbConverter />} />
        </Routes>
      </Suspense>
      <FooterComponent />
    </Router>
  );
}

export default App;
